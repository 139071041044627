import React, { useState, createContext, useEffect } from "react"

const defaultState = {
  theme: "light",
  lang: "de",
  changeTheme: () => { },
  changeLang: () => { },
}

const ThemeContext = createContext(defaultState)

function ThemeProvider({ children }) {
  const [supportsDarkModeReplaced, setSupportsDarkModeReplaced] = useState(false)
  const [supportsDarkMode, setSupportsDarkMode] = useState(false)
  const [theme, setTheme] = useState(false)
  const [lang, setLang] = useState("de")

  const changeTheme = theme => {
    if (supportsDarkMode && theme === "dark") {
      localStorage.setItem("supportsDarkModeReplaced", true)
      setSupportsDarkModeReplaced(true)
    }
    if (supportsDarkMode && theme === "light") {
      localStorage.setItem("supportsDarkModeReplaced", false)
      setSupportsDarkModeReplaced(false)
    }
    localStorage.setItem("theme", theme)
    setTheme(theme)
  }
  const changeLang = lang => {
    localStorage.setItem("lang", lang)
    setLang(lang)
  }

  useEffect(() => {
    const lsDark = localStorage.getItem("theme")
    const lang = localStorage.getItem("lang")
    const darkModeReplaced = localStorage.getItem("supportsDarkModeReplaced")

    if (lsDark === "dark") setTheme(lsDark)
    if (lang) setLang(lang)

    setSupportsDarkMode(window.matchMedia("(prefers-color-scheme: dark)").matches === true)

    if (supportsDarkMode && !darkModeReplaced) {
      setTheme("dark")
    }

    return () => {
      setTheme(false)
      setLang("de")
    }
  }, [theme, lang, supportsDarkMode])

  return (
    <ThemeContext.Provider
      value={{
        theme,
        lang,
        changeTheme,
        changeLang,
      }}
    >
      {children}
    </ThemeContext.Provider>
  )
}

export default ThemeContext

export { ThemeProvider }
