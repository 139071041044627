/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import "./src/scss/index.scss"
import "./src/css/all.min.css"

import React from "react"
// import ReactDOM from 'react-dom'

import { ThemeProvider } from "./src/context/index"

export const wrapRootElement = ({ element }) => (
  <ThemeProvider>{element}</ThemeProvider>
)

// export const replaceHydrateFunction = () => {
//   return (element, container, callback) => {
//     ReactDOM.render(element, container, callback);
//   };
// };