module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Zerewan Bro","short_name":"Zerewan Bro","description":"Deine digitale Lösung. Zusammen werden wir deine Idee in eine digitale Plattform umsetzten.","background_color":"#16111e","theme_color":"#d3dbde","lang":"de","start_url":"/","display":"standalone","icon":"static/favicon.jpg","checkSupportedExtensions":false,"crossOrigin":"use-credentials","theme_color_in_head":true,"legacy":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"9227030aed6db53c3874d5c834b6b395"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
